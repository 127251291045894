import React, { useState } from 'react';
import '../css/TimeLine.css';
import Popup from './Popup'; // Import Popup component

const timelineData = [
  {
    date: 'Julho 1 - 2024',
    description: 'Submissão de resumos para apresentações orais e posters.',
    button: 'Submeter Trabalhos',
  },
  {
    date: 'Novembro 13 - 2024',
    description: 'Abertura das Inscrições',
    button: 'Inscrição', // Added button for "Outubro 1"
  },
  {
    date: 'Dezembro 15',
    description: 'Prazo final para submissão de resumos',
  },
  {
    date: 'Janeiro 10 - 2025',
    description: 'Resposta da aceitação ou rejeição dos resumos',
  },
  {
    date: 'Abril 4 e 5 - 2025',
    description: 'CIRC 2025',
  },
];

function TimeLine() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleAcceptTerms = () => {
    window.location.href =
      'https://circ.eventqualia.net/pt/cloud/circ7787/account/login/';
  };

  const handleButtonClick = (url) => {
    window.location.href = url;
  };

  return (
    <div className="timeline">
      <h2>Cronologia do Evento</h2>
      <div className="timeline-container">
        <div className="timeline-line"></div>
        {timelineData.map((item, index) => (
          <div className="timeline-item" key={index}>
            <div className="timeline-circle"></div>
            <div className="timeline-card">
              <h4>{item.date}</h4>
              <h4>{item.title}</h4>
              <p>{item.description}</p>
              {item.button && (
                <button
                  className="timeline-button"
                  onClick={() =>
                    handleButtonClick(
                      item.button === 'Submeter Trabalhos'
                        ? 'https://eu.jotform.com/form/241273222563349#preview'
                        : item.button === 'Inscrição'
                        ? 'https://circ.eventqualia.net/pt/cloud/circ7787/account/login/'
                        : '#',
                    )
                  }
                >
                  {item.button}
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
      <Popup
        isOpen={isPopupOpen}
        togglePopup={togglePopup}
        handleAcceptTerms={handleAcceptTerms}
        handleViewRegulations={() => {}}
      />
    </div>
  );
}

export default TimeLine;
