import React from 'react';
import './Commission.css';

const ScientCommission = () => {
  return (
    <div className="commission-container">
      <h1>Comissão científica</h1>
      <ul className="commission-list">
        <li>Adélia Santos</li>
        <li>Adriana Baeta</li>
        <li>Alda Pinto</li>
        <li>Ana Carvalho</li>
        <li>Ana Cruz</li>
        <li>Ana Isabel Machado</li>
        <li>Ana Leitão</li>
        <li>Anabela Teixeira</li>
        <li>Bruno Esteves</li>
        <li>Carla Lourenço</li>
        <li>Carla Solano</li>
        <li>Carlos Nujo</li>
        <li>Carolina Gomes</li>
        <li>Daniel Matos</li>
        <li>Diana Carvalho</li>
        <li>Elza Santos</li>
        <li>Filipa Martins</li>
        <li>Filipa Pires</li>
        <li>Gracinda Valente</li>
        <li>Inês Santos</li>
        <li>Isabel Dias</li>
        <li>João Pedro</li>
        <li>Lucília Santos</li>
        <li>Magda Malva</li>
        <li>Marta Rosa</li>
        <li>Monica Martins</li>
        <li>Nuno Rodrigues</li>
        <li>Paula Marques</li>
        <li>Ricardo Leal</li>
        <li>Sandra Assunção</li>
        <li>Sandra Rodrigues</li>
        <li>Sara Silva</li>
        <li>Sónia Oliveira</li>
        <li>Teresa Fátima</li>
        <li>Tiago Araújo</li>
        <li>Tiago Patrão</li>
      </ul>
    </div>
  );
};

export default ScientCommission;
