// src/pages/Maintenance.js
import React from 'react';
import '../css/Maintenance.css';

function Maintenance() {
  return (
    <div className="maintenance">
      <div className="maintenance-content">
        <h1>Em Breve</h1>
        <p>O site oficial estará disponível dentro de dias. Fique atento!</p>
        <p>Para mais informações, entre em contato conosco:</p>
        <p>Email: circ.chuc@gmail.com</p>
      </div>
    </div>
  );
}

export default Maintenance;
