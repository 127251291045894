import React, { useEffect } from 'react';
import '../App.css';

const Section = ({ title, children }) => (
  <section className="cookies-policy-section margin-standard">
    <h3 className="section-title left-align">{title}</h3>
    <div className="section-content justify-text">{children}</div>
  </section>
);

const List = ({ items }) => (
  <ul className="cookies-policy-list">
    {items.map((item, index) => (
      <li key={index} className="list-item">
        {item}
      </li>
    ))}
  </ul>
);

const CookiesPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="cookies-policy">
      <h2 className="main-title">Política de Cookies</h2>
      <Section title="1. O que são cookies?">
        <p>
          Cookies são pequenos ficheiros de texto que são colocados no seu
          dispositivo para ajudar o website a fornecer uma melhor experiência de
          utilizador.
        </p>
      </Section>
      <Section title="2. Tipos de Cookies que Utilizamos">
        <List
          items={[
            '2.1. Cookies de Sessão: São temporários e permanecem no seu dispositivo até que feche o navegador.',
            '2.2. Cookies Persistentes: Permanecem no seu dispositivo por um período definido ou até que os elimine.',
          ]}
        />
      </Section>
      <Section title="3. Finalidade dos Cookies">
        <List
          items={[
            '3.1. Lembrar as suas preferências.',
            '3.2. Melhorar a navegação no site.',
            '3.3. Analisar o tráfego do site.',
            '3.4. Personalizar o conteúdo e anúncios.',
          ]}
        />
      </Section>
      <Section title="4. Gestão de Cookies">
        <p>
          Pode controlar e/ou eliminar cookies conforme desejar. Pode eliminar
          todos os cookies que já estão no seu dispositivo e configurar a
          maioria dos navegadores para impedir que sejam colocados. No entanto,
          se o fizer, poderá ter que ajustar manualmente algumas preferências
          sempre que visitar um site e alguns serviços e funcionalidades poderão
          não funcionar.
        </p>
      </Section>
      <Section title="5. Consentimento">
        <p>
          Ao utilizar o nosso website, você concorda com a utilização de cookies
          conforme descrito nesta Política de Cookies.
        </p>
      </Section>
      <Section title="6. Data de Entrada em Vigor">
        <p>
          <strong>Data de Entrada em Vigor:</strong> Esta Política de Cookies
          foi atualizada pela última vez em 29-11-2023.
        </p>
      </Section>
    </section>
  );
};

export default CookiesPolicy;
