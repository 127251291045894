// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/js/Navbar';
import Home from './pages/Home';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';
import Footer from './components/js/Footer';
import CookiesConsent from './components/js/CookiesConsent';
import IntroVideo from './components/js/IntroVideo';
import CookiesPolicy from './pages/CookiesPolicy';
import EventRegulation from './pages/EventRegulation';
import Maintenance from './components/js/Maintenance';
import './App.css';
import Contacts from './pages/Contacts';
import Association from './pages/Association';
import OrgCommission from './pages/OrgCommission';
import TecCommission from './pages/TecCommission';
import ScientCommission from './pages/ScientCommission';
import MedicalRadiologyExhibition from './pages/Exhibition';
import Sponsor from './components/js/Sponsors';

function App() {
  const [showIntro, setShowIntro] = useState(false);

  useEffect(() => {
    const hasSeenIntro = localStorage.getItem('hasSeenIntro');
    if (!hasSeenIntro) {
      setShowIntro(true);
      localStorage.setItem('hasSeenIntro', 'true');
    }
  }, []);

  const handleSkip = () => {
    setShowIntro(false);
  };

  return (
    <Router>
      <div className="App">
        {showIntro ? (
          <IntroVideo onSkip={handleSkip} />
        ) : (
          <>
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-of-use" element={<TermsOfUse />} />
              <Route path="/cookies-policy" element={<CookiesPolicy />} />
              <Route
                path="/exhibition"
                element={<MedicalRadiologyExhibition />}
              />
              <Route path="/contacts" element={<Contacts />} />
              <Route path="/event-regulation" element={<EventRegulation />} />
              <Route path="/association" element={<Association />} />
              <Route path="/org-commission" element={<OrgCommission />} />
              <Route path="/tec-commission" element={<TecCommission />} />
              <Route path="/scient-commission" element={<ScientCommission />} />
            </Routes>
            <Sponsor />
            <Footer />
            <CookiesConsent />
          </>
        )}
      </div>
    </Router>
  );
}

export default App;
