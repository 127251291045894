// src/components/js/Navbar.js
import React, { useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { Link } from 'react-router-dom';
import Popup from './Popup';
import '../css/Navbar.css';
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaPenNib,
} from 'react-icons/fa';

function Navbar() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
    setIsMenuOpen(false); // Close the menu when the button is clicked
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const handleAcceptTerms = () => {
    window.location.href =
      'https://circ.eventqualia.net/pt/cloud/circ7787/account/login/';
  };

  const handleViewRegulations = () => {
    window.location.href = '/event-regulation';
  };

  const handleLogoClick = () => {
    window.location.href = '/';
  };

  return (
    <header className="navbar">
      <div className="logo-container" onClick={handleLogoClick}>
        <img src="/logo.png" alt="CIRC Logo" className="logo-large" />
      </div>
      <nav className="desktop-menu">
        <Link className="menu-item" to="/" onClick={closeMenu}>
          INÍCIO
        </Link>
        <Link className="menu-item" to="/exhibition/" onClick={closeMenu}>
          EXHIBITION
        </Link>
        <Link className="menu-item" to="/schedule" onClick={closeMenu}>
          PROGRAMA
        </Link>
        <Link className="menu-item" to="/sponsors" onClick={closeMenu}>
          PARCERIAS
        </Link>
        <Link className="menu-item" to="/hotels/" onClick={closeMenu}>
          HOTEL/REST
        </Link>
        <Link className="menu-item" to="/contacts" onClick={closeMenu}>
          CONTACTOS
        </Link>
        <Link
          className="menu-item inscription-highlight"
          to="#"
          onClick={togglePopup}
        >
          INSCRIÇÃO
        </Link>
      </nav>
      <Menu
        right
        isOpen={isMenuOpen}
        onStateChange={({ isOpen }) => setIsMenuOpen(isOpen)}
        styles={{ bmMenuWrap: { top: '0', height: '100%', width: '100%' } }}
      >
        <div className="menu-logo-container">
          <img src="/logo.png" alt="CIRC Logo" className="logo-small" />
        </div>
        <Link
          className="menu-item menu-item-separator"
          to="/"
          onClick={closeMenu}
        >
          INÍCIO
        </Link>
        <Link
          className="menu-item menu-item-separator"
          to="/exhibition/"
          onClick={closeMenu}
        >
          EXHIBITION
        </Link>
        <Link
          className="menu-item menu-item-separator"
          to="/schedule"
          onClick={closeMenu}
        >
          PROGRAMA
        </Link>
        <Link
          className="menu-item menu-item-separator"
          to="/sponsors"
          onClick={closeMenu}
        >
          PARCERIAS
        </Link>
        <Link
          className="menu-item menu-item-separator"
          to="/hotels/"
          onClick={closeMenu}
        >
          HOTEL/REST
        </Link>
        <Link
          className="menu-item menu-item-separator"
          to="/contacts"
          onClick={closeMenu}
        >
          CONTACTOS
        </Link>
        <Link
          className="menu-item menu-item-separator inscription-highlight"
          to="#"
          onClick={togglePopup}
        >
          INSCRIÇÃO
        </Link>
      </Menu>
      <Popup
        isOpen={isPopupOpen}
        togglePopup={togglePopup}
        handleAcceptTerms={handleAcceptTerms}
        handleViewRegulations={handleViewRegulations}
      />
    </header>
  );
}

export default Navbar;
