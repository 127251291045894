import React, { useEffect } from 'react';
import '../App.css';

const Section = ({ title, children }) => (
  <section className="terms-of-use-section">
    <h3 className="section-title left-align">{title}</h3>
    <div className="section-content justify-text">{children}</div>
  </section>
);

const List = ({ items }) => (
  <ul className="terms-of-use-list">
    {items.map((item, index) => (
      <li key={index} className="list-item">
        {item}
      </li>
    ))}
  </ul>
);

const TermsOfUse = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="terms-of-use">
      <h2 className="main-title">Termos de Utilização</h2>
      <Section title="1. Introdução">
        <p>
          Bem-vindo ao site oficial <strong>"circ-cb.com"</strong>. Ao continuar
          a navegar e utilizar este website, você concorda em cumprir e estar
          vinculado aos seguintes termos e condições de utilização, que,
          juntamente com a nossa Política de Privacidade, regem a relação entre{' '}
          <strong>circ-cb.com.com</strong> e você em relação a este website. Se
          discordar de alguma parte destes termos e condições, por favor, não
          utilize o nosso website.
        </p>
      </Section>
      <Section title="2. Utilização deste website">
        <List
          items={[
            '2.1. Aviso de Conteúdo: O conteúdo das páginas deste website destina-se apenas a informação geral e uso. Está sujeito a alterações sem aviso prévio.',
            '2.2. Utilização de Cookies: Este website utiliza cookies para monitorizar as preferências de navegação. Se permitir a utilização de cookies, informações pessoais podem ser armazenadas por nós para uso por terceiros.',
            '2.3. Privacidade: A sua utilização deste website também é regida pela nossa Política de Privacidade. Por favor, reveja a nossa Política de Privacidade para obter mais informações sobre como recolhemos e utilizamos dados.',
            '2.4. Conduta do Utilizador: A utilização não autorizada deste website pode dar origem a uma reivindicação por danos e/ou constituir um delito criminal.',
            '2.5. Ligações para Outros Websites: O nosso website pode conter ligações para outros websites. Essas ligações são fornecidas para sua conveniência para fornecer mais informações. Elas não significam que endossamos o(s) website(s). Não temos responsabilidade pelo conteúdo do(s) website(s) vinculado(s).',
            '2.6. Renúncia de Garantia: Nem nós nem terceiros fornecemos qualquer garantia quanto à exatidão, pontualidade, desempenho, integridade ou adequação das informações e materiais encontrados ou oferecidos neste website para qualquer finalidade específica. Reconhece que tais informações e materiais podem conter imprecisões ou erros, e renunciamos expressamente à responsabilidade por tais imprecisões ou erros na extensão máxima permitida por lei.',
            '2.7. Propriedade Intelectual: Este website contém material que é propriedade ou licenciado para nós. Este material inclui, mas não está limitado ao design, layout, aparência e gráficos. A reprodução é proibida, exceto em conformidade com o aviso de direitos autorais, que faz parte destes termos e condições.',
            '2.8. Alterações aos Termos: circ-cb.com pode rever estes termos de utilização do seu website a qualquer momento sem aviso prévio. Ao utilizar este website, você concorda em ficar vinculado à versão então atualizada destes Termos de Utilização.',
            '2.9. Informações de Contacto: Se tiver alguma dúvida sobre estes Termos de Utilização, entre em contacto connosco em circ.chuc@gmail.com.',
          ]}
        />
      </Section>
      <Section title="3. Data de Entrada em Vigor">
        <p>
          <strong>Data de Entrada em Vigor:</strong> Estes Termos de Utilização
          foram atualizados pela última vez em 29-11-2023.
        </p>
      </Section>
    </section>
  );
};

export default TermsOfUse;
