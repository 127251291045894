import React, { useState, useEffect, useRef } from 'react';
import '../css/Popup.css';

function Popup({
  isOpen,
  togglePopup,
  handleAcceptTerms,
  handleViewRegulations,
}) {
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false); // New state
  const termsRef = useRef(null);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false); // Define state for button enabled

  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };

  const handleAcceptTermsClick = () => {
    handleAcceptTerms();
    window.open('https://example.com', '_blank'); // Open a new window
  };

  useEffect(() => {
    const handleScroll = () => {
      if (termsRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = termsRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 1) {
          // Verifica se chegou ao fim
          setIsButtonEnabled(true); // Enable the button
        }
      }
    };

    if (termsRef.current) {
      termsRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (termsRef.current) {
        termsRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    isOpen && (
      <div className="popup">
        <div className="popup-content">
          <span className="close" onClick={togglePopup}>
            &times;
          </span>
          {!acceptedTerms ? (
            <div>
              <h2>Termos e Condições</h2>
              <div
                className="terms-container"
                ref={termsRef}
                style={{
                  textAlign: 'justify',
                  fontFamily: 'Arial, sans-serif',
                  lineHeight: '1.6',
                }}
              >
                <h3>Inscrições - Resumo do Regulamento</h3>
                <p>
                  <strong>1. Informações Gerais</strong>
                </p>
                <p>Lotação: O evento tem vagas limitadas.</p>
                <p>
                  Plataforma de Inscrição: Inscrição exclusiva pela plataforma
                  INSCRIÇÕES (sem aceitação de inscrições em grupo).
                </p>
                <p>
                  Verificação de Dados: Os dados fornecidos serão usados para
                  gerar faturas, recibos e certificados. Verifique todos os
                  detalhes antes de concluir.
                </p>
                <p>
                  <strong>2. Tipos de Inscrição</strong>
                </p>
                <p>
                  Categorias: Inscrição presencial, virtual, estudantes,
                  congressistas internos (ULS Coimbra) e externos.
                </p>
                <p>
                  Estudantes IMR: Devem enviar prova de condição de estudante
                  para congress@eventqualia.net (com CC para
                  circ.chuc@gmail.com).
                </p>
                <p>
                  <strong>3. Pagamento e Confirmação</strong>
                </p>
                <p>
                  Confirmação de Inscrição: Apenas inscrições pagas serão
                  confirmadas.
                </p>
                <p>
                  Código de Acesso Online: Será fornecido para inscrições
                  presenciais e virtuais.
                </p>
                <p>
                  <strong>4. Comunicação</strong>
                </p>
                <p>
                  Contato: Preferencialmente via email (congress@eventqualia.net
                  com CC para circ.chuc@gmail.com).
                </p>
                <p>
                  Notificação de Pagamentos e Cancelamentos: Toda a
                  correspondência será enviada por email.
                </p>
                <p>
                  <strong>5. Cancelamento e Substituição de Inscrições</strong>
                </p>
                <p>Cancelamento:</p>
                <ul>
                  <li>Até 1 dia após inscrição: Reembolso de 75%.</li>
                  <li>Até 2 semanas após inscrição: Reembolso de 50%.</li>
                  <li>Após 2 semanas: Sem reembolso.</li>
                </ul>
                <p>
                  Substituição: Permitida até 1 de março de 2025 (mediante
                  aprovação da comissão organizadora).
                </p>
                <p>
                  Solicitações Após o Congresso: Custos adicionais de 50€
                  aplicam-se para pedidos até 3 meses após o evento.
                </p>
                <p>
                  <strong>6. Política de Privacidade</strong>
                </p>
                <p>
                  Proteção de Dados: Cumprimento do Regulamento Europeu
                  2016/679.
                </p>
                <p>
                  Uso de Dados: Dados utilizados para comunicação, envio de
                  documentos e newsletters. Os dados serão protegidos e
                  armazenados com segurança.
                </p>
                <p>
                  Direitos do Participante: Direito de acesso, correção,
                  esquecimento, e portabilidade dos dados. Qualquer solicitação
                  deve ser enviada para circ.chuc@gmail.com.
                </p>
                <p>
                  Alterações à Política: Qualquer atualização será destacada no
                  site do congresso.
                </p>
                <p>
                  <strong>7. Direitos de Imagem</strong>
                </p>
                <p>
                  Gravação e Publicação: O evento será gravado e fotografado, e
                  os materiais poderão ser publicados em redes sociais e outras
                  plataformas. A participação implica a autorização dos direitos
                  de imagem.
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '20px',
                  gap: '20px',
                }}
              >
                <label>
                  <input
                    type="checkbox"
                    checked={isCheckboxChecked}
                    onChange={handleCheckboxChange}
                  />
                  Li e aceito as condições do regulamento
                </label>
                <button
                  onClick={handleAcceptTermsClick} // Use the new function
                  style={{
                    cursor: isCheckboxChecked ? 'pointer' : 'not-allowed', // Conditionally enable the button
                    backgroundColor: isCheckboxChecked ? '#007bff' : '#ccc', // Conditionally change color
                    color: '#fff',
                    border: 'none',
                    padding: '10px 20px',
                    borderRadius: '5px',
                    transition: 'background-color 0.3s',
                  }}
                  onMouseEnter={(e) => {
                    if (isCheckboxChecked) {
                      e.target.style.backgroundColor = '#0056b3';
                    }
                  }}
                  onMouseLeave={(e) => {
                    if (isCheckboxChecked) {
                      e.target.style.backgroundColor = '#007bff';
                    }
                  }}
                  disabled={!isCheckboxChecked} // Disable the button
                >
                  Aceitar
                </button>
                <button
                  onClick={handleViewRegulations}
                  style={{
                    cursor: 'pointer',
                    backgroundColor: '#007bff',
                    color: '#fff',
                    border: 'none',
                    padding: '10px 20px',
                    borderRadius: '5px',
                    transition: 'background-color 0.3s',
                  }}
                  onMouseEnter={(e) =>
                    (e.target.style.backgroundColor = '#0056b3')
                  }
                  onMouseLeave={(e) =>
                    (e.target.style.backgroundColor = '#007bff')
                  }
                >
                  Ver Regulamento Completo
                </button>
              </div>
            </div>
          ) : (
            <div>
              <h2>Obrigado por aceitar os termos!</h2>
              <p>Você será redirecionado para a página de convite.</p>
            </div>
          )}
        </div>
      </div>
    )
  );
}

export default Popup;
