// src/components/js/Sponsors.js
import React from 'react';
import '../css/Sponsors.css';

const sponsors = [
  { name: 'Sponsor 1', logo: '/img/partner-logo/logo-1.png' },
  { name: 'Sponsor 3', logo: '/img/partner-logo/logo-3.png' },
  { name: 'Sponsor 4', logo: '/img/partner-logo/logo-4.png' },
  // Adicione mais patrocinadores conforme necessário
];

function Sponsors() {
  return (
    <section className="sponsors">
      <div className="sponsors-logos">
        {sponsors.map((sponsor, index) => (
          <div key={index} className="sponsor">
            <img src={sponsor.logo} alt={sponsor.name} />
          </div>
        ))}
      </div>
    </section>
  );
}

export default Sponsors;
