import React, { useEffect } from 'react';
import '../App.css';

const Section = ({ title, children }) => (
  <section className="terms-of-use-section">
    <h3 className="section-title left-align">{title}</h3>
    <div className="section-content justify-text">{children}</div>
  </section>
);

const List = ({ items }) => (
  <ul className="terms-of-use-list">
    {items.map((item, index) => (
      <li key={index} className="list-item">
        {item}
      </li>
    ))}
  </ul>
);

const EventRegulation = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="terms-of-use">
      <h2 className="main-title">Regulamento do Evento</h2>

      <Section title="INSCRIÇÕES">
        <p>
          Solicitamos que leia atentamente um conjunto de regras antes de se
          inscrever.
        </p>
        <List
          items={[
            'Este evento tem lotação limitada.',
            'A privacidade e tratamento de dados cumpre uma escrupulosa política de privacidade (ver abaixo).',
            'A inscrição é efetuada exclusivamente pela plataforma INSCRIÇÕES.',
            'A plataforma não aceita inscrições de grupos.',
            'Para realizar a sua inscrição deverá ler atentamente este REGULAMENTO e preencher corretamente todos os dados solicitados.',
            'Verifique os detalhes da inscrição, os dados pessoais, e-mail e a morada de faturação/recibo.',
            'Os dados preenchidos na sua área pessoal, nos campos de perfil de utilizador e no pedido de fatura, serão automaticamente importados originando todo o seu processo: faturas, recibos, certificados.',
            'O valor a pagar dependerá da data da inscrição, do perfil de inscrição adequado à sua situação profissional e do tipo de inscrição: presencial, virtual, estudante, congressista interno (pertencente ao quadro da ULS Coimbra), congressista externo ou outro formato que a organização possa promover.',
            'Apenas serão considerados como inscritos no congresso os participantes que efetivaram o pagamento.',
            'Os participantes que se inscrevam como “Estudantes Licenciatura em IMR”, deverão enviar por email, para congress@eventqualia.net, com CC circ.chuc@gmail.com, uma prova da condição de estudante válida para o ano letivo em que é realizada a inscrição (cartão de estudante, declaração, etc.).',
            'À inscrição virtual está inerente um único acesso online, via plataforma telemática, e respetivo código de acesso, intransmissível, que será fornecido ao participante via correio eletrónico, disponibilizado na inscrição, nos dias que antecedem o evento.',
            'Todos os participantes que efetuarem inscrição presencial também terão acesso a um código de acesso online, via plataforma telemática, intransmissível, e fornecido ao participante via correio eletrónico nos dias que antecedem o evento.',
            'Toda a correspondência com os inscritos no congresso, relativamente a pagamentos, será feita preferencialmente por correio eletrónico, através do e-mail congress@eventqualia.net com CC de circ.chuc@gmail.com.',
            'Qualquer necessidade de cancelamento ou substituição de inscrição no congresso deverá cumprir a política de cancelamento e substituição de inscrições no congresso (ver abaixo).',
            'Todos os participantes no congresso, seja presencial ou virtual, terão acesso a um certificado de participação (formato digital), que será disponibilizado depois de feito o check in e terminado o evento.',
            'A comissão organizadora assume-se no direito de cumprir e fazer cumprir todas as orientações emitidas pela DGS, à data do evento, relativamente à lotação de participantes e medidas de higiene e segurança a adotar.',
            'A comissão organizadora possui um seguro de responsabilidade civil no valor de “..........”.',
            'Em caso de adiamento / cancelamento do congresso por motivos externos à comissão organizadora, será devolvido 75% do valor da inscrição.',
            'Por motivos alheios à comissão organizadora, os e-mails enviados poderão ir para o spam, pelo que solicitamos que verifique frequentemente o seu email.',
          ]}
        />
      </Section>

      <Section title="POLÍTICA DE CANCELAMENTO E SUBSTITUIÇÃO DE INSCRIÇÕES NO CONGRESSO">
        <p>
          Mediante contacto por correio eletrónico e após análise do requerido,
          proceder-se-á da seguinte forma:
        </p>
        <List
          items={[
            'Solicitações de cancelamento até 1 dia após inscrição, haverá reembolso de 75% do valor;',
            'Solicitações de cancelamento até duas semanas após inscrição efetiva, haverá reembolso de 50% do valor;',
            'Solicitações de cancelamento depois de duas semanas após inscrição efetiva, não haverá direito a qualquer reembolso;',
            'Até 1 de março 2025 poderá ser solicitada substituição da inscrição, mediante contacto com a comissão organizadora, e posterior aceitação.',
            'Qualquer solicitação fora de tempo (até 3 meses após o fim do congresso) tem o custo de 50€, devidas à EVENTQUALIA.',
            'Após esta data, a decisão é exclusivamente da responsável da EVENTQUALIA, tal como os custos.',
          ]}
        />
      </Section>

      <Section title="POLÍTICA DE PRIVACIDADE">
        <p>
          A presente Política de Privacidade descreve a forma como serão
          tratados os seus dados pessoais e o modo como estamos empenhados em
          proteger a sua privacidade.
        </p>
        <List
          items={[
            'A comissão organizadora é a entidade responsável pela recolha e tratamento dos seus dados pessoais e obriga-se no direito de garantir a privacidade, segurança e confidencialidade dos dados pessoais que lhe sejam transmitidos, seja por via online, seja por qualquer outra via. Na recolha, processamento, armazenamento e guarda dos dados pessoais, agirá em conformidade com a legislação que, ao tempo, estiver em vigor, nomeadamente do Regulamento Europeu 2016/679 do Parlamento Europeu e do Conselho, o qual é respeitante à proteção de pessoas singulares no que concerne ao tratamento de livre circulação de dados pessoais.',
            'A recolha dos dados pessoais é efetuada no momento da inscrição no congresso. Tal informação é necessária e obrigatória para assegurar a identidade do participante. Poderemos utilizar os seus dados com as seguintes finalidades:',
            'Transmitir informações científicas relativas ao evento; divulgar atividades;',
            'Envio de publicidade / newsletters por terceiros, nomeadamente entidades apoiantes do evento.',
            'Enviar documentos decorrentes dos seus direitos do participante.',
            'O participante poderá atualizar as informações por si fornecidas, mediante envio de um e-mail para circ.chuc@gmail.com identificando-se e referindo quais os dados a ser alterados.',
            'Os seus dados pessoais serão conservados apenas por um período necessário para cumprir as finalidades descritas na presente Política de Privacidade, salvo se um período de conservação mais longo for requerido ou imposto por lei.',
            'A comissão organizadora protege os dados pessoais tomando todas as medidas legalmente exigidas para garantir a segurança online e offline da informação que lhe é transmitida. Tais medidas visam proteger os seus dados pessoais contra a sua destruição, perda ou modificação acidental, bem como para evitar o acesso indevido aos mesmos e incluem a encriptação de dados e a segurança das aplicações que os processam.',
            'Nos termos da legislação em vigor, o participante tem direito a opor-se, sem encargos, ao tratamento dos seus dados pessoais para fins de informação, bem como, entre outros, os seguintes direitos adicionais: Direito à correção dos dados; Direito ao esquecimento; Direito de apagar em definitivo os seus dados pessoais; Direito à portabilidade dos dados e a receber uma cópia dos dados processados a si respeitantes.',
            'Para qualquer questão relativa à proteção dos seus dados pessoais poderá contactar a comissão organizadora através do email circ.chuc@gmail.com.',
            'Esta Política de Privacidade poderá ser objeto de atualização regular em ordem a uma melhor proteção dos dados que nos são confiados;',
            'Qualquer alteração da presente Política de Privacidade será objeto de comunicação com saliência no site do congresso.',
          ]}
        />
      </Section>

      <Section title="POLÍTICA DOS DIREITOS DE IMAGEM">
        <p>
          Este evento vais ser gravado em streaming, os palestrantes
          fotografados, e posteriormente colocado em redes sociais, canal de
          Youtube, outros e tem implícito a autorização dos direitos de imagem e
          das comunicações de todos os participantes, congressistas,
          palestrantes, parceiros.
        </p>
      </Section>
    </section>
  );
};

export default EventRegulation;
