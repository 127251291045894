import React, { useEffect } from 'react';
import '../App.css';

const Section = ({ title, children }) => (
  <section className="terms-of-use-section">
    <h3 className="section-title left-align">{title}</h3>
    <div className="section-content justify-text">{children}</div>
  </section>
);

const List = ({ items }) => (
  <ul className="terms-of-use-list">
    {items.map((item, index) => (
      <li key={index} className="list-item">
        {item}
      </li>
    ))}
  </ul>
);

const contacts = {
  address: [
    'CIRC - Congresso Internacional de Radiologia de Coimbra',
    'Av. da Guarda Inglesa 1a',
    '3040-193 Coimbra - Portugal',
  ],
  registrations: {
    title: 'Receção de inscrições',
    name: 'Eventqualia',
    email: 'congress@eventqualia.net',
  },
  submissions: {
    title: 'Receção de submissões',
    name: 'Carla Solano',
    email: 'nucleodeformacaotr@gmail.com',
  },
  internationalRelations: {
    title: 'Relações internacionais',
    name: 'Paula Marques',
    email: 'circ.chuc@gmail.com',
  },
  exhibition: {
    title: 'MEDICAL RADIOLOGY EXHIBITION\nReserva de stands',
    name: 'Carla Solano',
    email: 'circ.chuc@gmail.com',
  },
  accommodation: {
    title: 'Alojamento e Restauração',
    name: 'Marta Rosa',
    email: 'circ.chuc@gmail.com',
  },
  otherInfo: {
    title: 'Outras informações',
    email: 'circ.chuc@gmail.com',
  },
};

const ContactsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="terms-of-use">
      <h2 className="main-title">Contactos</h2>
      <Section title="Endereço">
        <List items={contacts.address} />
      </Section>

      {Object.values(contacts)
        .slice(1)
        .map((contact, index) => (
          <Section key={index} title={contact.title}>
            <p>{contact.name}</p>
            <p>e-mail: {contact.email}</p>
          </Section>
        ))}
    </section>
  );
};

export default ContactsPage;
