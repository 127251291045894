import React from 'react';
import './Commission.css';

const TecCommission = () => {
  return (
    <div className="commission-container">
      <h1>Comissões técnicas</h1>
      <ul className="commission-list">
        <li>Alda Pinto</li>
        <li>Bruno Esteves</li>
        <li>Carla Solano</li>
        <li>Daniel Matos</li>
        <li>Elza Santos</li>
        <li>Marta Rosa</li>
        <li>Paula Marques</li>
        <li>Tiago Araújo</li>
        <li>Tiago Patrão</li>
      </ul>
    </div>
  );
};

export default TecCommission;
