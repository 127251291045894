import React, { useEffect } from 'react';
import '../App.css';

const Section = ({ title, children }) => (
  <section className="privacy-policy-section">
    <h3 className="section-title left-align">{title}</h3>
    <div className="section-content justify-text">{children}</div>
  </section>
);

const List = ({ items }) => (
  <ul className="privacy-policy-list">
    {items.map((item, index) => (
      <li key={index} className="list-item">
        {item}
      </li>
    ))}
  </ul>
);

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="privacy-policy">
      <h2 className="main-title">Política de Privacidade</h2>

      <Section title="1. Identificação do Responsável pelo Tratamento">
        <p>
          Nome: Congresso Internacional de Radiologia de Coimbra - Eyes on the
          Future (CIRC2023)
          <br />
          Sede: Rua João Moreno, Lote 1, Loja B 3030-776, Coimbra.
          <br />
          Contacto Encarregado da Proteção de Dados: Carla Maria Solano Máximo
          da Rocha
          <br />
          Email: ahd-geral@outlook.com
        </p>
      </Section>

      <Section title="2. Informação e Consentimento">
        <p>
          A Lei da Proteção de Dados Pessoais (LPDP) e o Regulamento Geral de
          Proteção de Dados (RGPD) asseguram a proteção das pessoas singulares
          no que diz respeito ao tratamento de dados pessoais e à livre
          circulação desses dados.
        </p>
        <p>
          Nos termos legais são considerados "dados pessoais" qualquer
          informação, de qualquer natureza e independentemente do respetivo
          suporte, incluindo som e imagem, relativa a uma pessoa singular
          identificada ou identificável, pelo que a proteção não abrange os
          dados de pessoas coletivas.
        </p>
        <p>
          Mediante a aceitação da presente Política de Privacidade, o utilizador
          presta o seu consentimento informado, expresso, livre e inequívoco
          para que os dados pessoais fornecidos através do site sejam incluídos
          num ficheiro da responsabilidade do Congresso Internacional de
          Radiologia de Coimbra - Eyes on the Future, cujo tratamento cumpre as
          medidas de segurança técnicas e organizativas adequadas.
        </p>
      </Section>

      <Section title="3. Finalidades do Tratamento de Dados Pessoais">
        <List
          items={[
            '3.1. Processamento de encomendas;',
            '3.2. Comunicação com clientes/participantes e esclarecimento de dúvidas;',
            '3.3. Processamento de pedidos de informação;',
            '3.4. Processamento de reclamações;',
            '3.5. Atividades de análise estatística;',
            '3.6. Verificar, manter e desenvolver sistemas e análises estatísticas;',
            '3.7. Comunicações de marketing direto (caso tenha consentido no tratamento dos seus dados pessoais para esta finalidade);',
            '3.8. Prevenção e combate à fraude;',
            '3.9. Solicitação de comentários a produtos ou serviços adquiridos;',
            '3.10. Realização de inquéritos de satisfação;',
            '3.11. Gestão e participação em eventos, passatempos e campanhas.',
          ]}
        />
      </Section>

      <Section title="4. Cessão de Dados Pessoais">
        <p>
          O Congresso Internacional de Radiologia de Coimbra - Eyes on the
          Future poderá ceder os seus dados pessoais a outras entidades para
          finalidades específicas, como gestão de pagamentos e eventos.
        </p>
      </Section>

      <Section title="5. Medidas de Segurança">
        <p>
          O Congresso Internacional de Radiologia de Coimbra - Eyes on the
          Future declara que implementou medidas de segurança técnicas e
          organizativas necessárias para proteger os dados pessoais fornecidos,
          evitando a sua alteração, perda ou acesso não autorizado.
        </p>
      </Section>

      <Section title="6. Comunicações Comerciais e Promocionais">
        <p>
          Sempre que enviarmos comunicações comerciais ou promocionais, será
          apenas para utilizadores que tenham consentido previamente.
        </p>
      </Section>

      <Section title="7. Exercício dos Direitos">
        <p>
          O utilizador pode exercer os seus direitos de acesso, retificação,
          apagamento, limitação, oposição e portabilidade a qualquer momento.
          <br />
          Email: circ.chuc@gmail.com
          <br />
          Telefone: +351 917 635 120
        </p>
      </Section>

      <Section title="8. Autoridade de Controlo">
        <p>
          Nos termos legais, o titular dos dados tem o direito de apresentar uma
          reclamação à Comissão Nacional de Proteção de Dados (CNPD).
        </p>
      </Section>
    </section>
  );
};

export default PrivacyPolicy;
