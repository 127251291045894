import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaChevronUp,
} from 'react-icons/fa';
import '../css/Footer.css';
import ContactForm from './ContactForm';

function Footer() {
  const [isVisible, setIsVisible] = useState(false);

  // Mostrar o botão de "Back to the Top" após rolar 300px
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Rolar para o topo da página
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <>
      <footer className="footer">
        <div className="footer-columns">
          <div className="footer-column">
            <h3>Sobre</h3>
            <ul>
              <li>
                <Link to="/event-regulation" onClick={scrollToTop}>
                  Regulamento 2025
                </Link>
              </li>
              <li>
                <Link to="/association" onClick={scrollToTop}>
                  Associação
                </Link>
              </li>

              <li>CIRC2023</li>
              <li>
                <Link
                  to="http://www.radiologiaaocentro.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Radiologia ao Centro 2024
                </Link>
              </li>
              <li>
                <Link to="/privacy-policy" onClick={scrollToTop}>
                  Política de Privacidade
                </Link>
              </li>
              <li>
                <Link to="/terms-of-use" onClick={scrollToTop}>
                  Termos de Utilização
                </Link>
              </li>
              <li>
                <Link to="/cookies-policy" onClick={scrollToTop}>
                  Política de Cookies
                </Link>
              </li>
            </ul>
          </div>

          <div className="footer-column">
            <h3>Comissões</h3>
            <ul>
              <li>
                <Link to="/org-commission" onClick={scrollToTop}>
                  Comissão Organizadora
                </Link>
              </li>
              <li>
                <Link to="scient-commission" onClick={scrollToTop}>
                  Comissão Científica
                </Link>
              </li>
              <li>
                <Link to="/tec-commission" onClick={scrollToTop}>
                  Comissões Técnicas
                </Link>
              </li>
            </ul>
            <div className="footer-social">
              <a
                href="https://www.facebook.com/circ.chuc/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookF />
              </a>
              <a
                href="https://www.instagram.com/circ.chuc/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram />
              </a>
              <a
                href="https://www.linkedin.com/company/circ-chuc/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedinIn />
              </a>
            </div>
          </div>

          <div className="footer-column">
            <h3>Contacte-nos</h3>
            <ContactForm />
          </div>
        </div>

        <div className="footer-copyright">
          <p>&copy; 2025 Congresso Internacional de Radiologia de Coimbra</p>
        </div>
      </footer>

      {/* Back to Top Section */}
      {isVisible && (
        <div
          className="back-to-top"
          onClick={scrollToTop}
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            cursor: 'pointer',
            zIndex: '1000',
            backgroundColor: 'blue',
            padding: '10px',
            borderRadius: '50%',
          }}
        >
          <FaChevronUp style={{ color: '#FFFDD0' }} />
        </div>
      )}
    </>
  );
}

export default Footer;
