import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import '../css/HeroSection.css';

const slideImage1 = `${process.env.PUBLIC_URL}/img/related-post/related-post-1.jpg`;
const slideImage2 = `${process.env.PUBLIC_URL}/img/related-post/related-post-2.jpg`;
const slideImage3 = `${process.env.PUBLIC_URL}/img/related-post/related-post-3.jpg`;
const slideImage4 = `${process.env.PUBLIC_URL}/img/related-post/related-post-4.jpg`;

function HeroSection() {
  return (
    <section className="hero-section">
      <Swiper
        grabCursor={true}
        centeredSlides={true}
        slidesPerView="auto"
        loop={true}
        pagination={{ clickable: true }}
        navigation={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        modules={[Pagination, Navigation, Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="slide-content">
            <img src={slideImage2} alt="Slide 2" className="slide-image" />
            <div className="text-overlay">
              <h4>
                <Link to="https://eu.jotform.com/form/241273222563349#preview">
                  Submissão de Trabalhos
                </Link>
              </h4>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="slide-content">
            <img src={slideImage1} alt="Slide 1" className="slide-image" />
            <div className="text-overlay">
              <h4>
                <Link to="/exhibition">Medical Exhibition</Link>
              </h4>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide-content">
            <img src={slideImage4} alt="Slide 4" className="slide-image" />
            <div className="text-overlay">
              <h4>
                <Link to="/schedule">Programa Oficial 2025</Link>
              </h4>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide-content">
            <img src={slideImage3} alt="Slide 3" className="slide-image" />
            <div className="text-overlay">
              <h4>
                <Link to="/association">O Conceito</Link>
              </h4>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  );
}

export default HeroSection;
